import styled from 'styled-components'

import BuildOnBase from 'assets/svg/partners/landing/build-on-base.svg'
import PoweredBySynthetix from 'components/PoweredBySynthetix'
import media from 'styles/media'

const MainPartners = () => {
	return (
		<Container>
			<StyledPoweredBySynthetix logoWidth="106px" />
			<BuildOnBase />
		</Container>
	)
}

export default MainPartners

const Container = styled.div`
	display: flex;
	gap: 20px;
	position: absolute;
	bottom: 20px;
	left: $0px;
	z-index: 20;
	${media.lessThan('lg')`
  bottom: 91px;
	`}
`

const StyledPoweredBySynthetix = styled(PoweredBySynthetix)`
	border-right: ${(props) => props.theme.colors.selectedTheme.landing.border};
	align-items: flex-start;
	padding-right: 20px;
`
